import axios from 'axios';
import { SIGNIN } from './routeConstants';

export const api = axios.create({
    timeout: 180000,
    headers: {
        'client-version': localStorage.getItem('app_version') ?? "0.0.0"
    }
});

const logOut = () => {
    const currentAppVersion = localStorage.getItem('app_version') ?? '0.0.0';
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("app_version", currentAppVersion);
}

api.interceptors.request.use(
    (req: any) => {
        // Add configurations here
        return req;
    },
    (err: any) => {
        if (err.response.status === 401) {
            logOut();
            window.location.href = process.env.REACT_APP_URL + SIGNIN;
        } else {
            return Promise.reject(err);
        }
    }
);

// For POST requests
api.interceptors.response.use(
    (res: any) => {
        if (res.status === 201) {
        }
        return res;
    },
    (err: any) => {
        if (err.response.status === 418) {
            logOut();
            localStorage.setItem("app_version", err.response?.data?.latestVersion ?? '0.0.0');
            window.location.href = process.env.REACT_APP_URL + SIGNIN;
        }
        if (err.response.status === 401) {
            logOut();
            window.location.href = process.env.REACT_APP_URL + SIGNIN;
        } else {
            return Promise.reject(err);
        }
    }
);